import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { baseDomain } from "../endpoints";
import { Image } from "../globals/UIKit";
import Layout from "../components/layout";
import Seo from "../components/seo";
import fetch from "isomorphic-fetch";
import "../components/common/JwtConfig.scss";

const JwtConfig = props => {
  const [state, setState] = useState({
    currentProvider: null,
    applicationName: null,
    output: "",
    btnText: "Generate Config",
  });
  const ogImage = {
    ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/card.png",
  };
  const canonicalUrl = "https://hasura.io/jwt-config/";

  const checkExtraSlashes = url => {
    if (!url) {
      return url;
    }
    if (url[url.length - 1] === "/") {
      return url.slice(0, url.length - 1);
    }
    return url;
  };
  const getUrl = (currentProvider, applicationName) => {
    let finalUrl = null;
    if (currentProvider === "auth0") {
      // check if http or https:// prefixed
      const finalApplicationName = checkExtraSlashes(applicationName);
      if (finalApplicationName.includes("http://") || finalApplicationName.includes("https")) {
        finalUrl = finalApplicationName + "/pem";
      } else {
        finalUrl = "https://" + finalApplicationName + "/pem";
      }
    }
    return finalUrl;
  };
  const providerChanged = e => {
    setState({ ...state, currentProvider: e.target.value, output: "" });
  };
  const applicationNameChanged = e => {
    setState({ ...state, applicationName: e.target.value, output: "" });
  };
  const generateOutput = event => {
    // make http call
    event.preventDefault();
    if (state.currentProvider === null) {
      alert("Please select the provider");
    } else if (state.applicationName === null || state.applicationName === "") {
      alert("Please enter the application name/project ID");
    } else {
      setState({ ...state, btnText: "Generating..." });
      switch (state.currentProvider) {
        case "auth0":
          const url = getUrl(state.currentProvider, state.applicationName);
          const corsUrl = "https://hasura-cors-anywhere.herokuapp.com/";
          fetch(corsUrl + url)
            .then(response => {
              return response.text();
            })
            .then(data => {
              try {
                const parsedResp = data;
                const finalOutput =
                  '{"type": "RS512", "key": "' +
                  parsedResp.replace(/(?:\r\n|\r|\n)/g, "\\n") +
                  '"}';
                setState({
                  ...state,
                  output: finalOutput.toString(),
                  btnText: "Generate Config",
                });
              } catch (e) {
                alert("Could not generate. Check application name");
                setState({ ...state, btnText: "Generate Config" });
              }
            })
            .catch(e => {
              console.error(e);
              alert("Cannot generate. Please input valid values");
            });
          break;
        case "firebase":
          const issuer = "https://securetoken.google.com/" + state.applicationName;
          const finalOutput =
            '{"type":"RS256","jwk_url": "https://www.googleapis.com/service_accounts/v1/jwk/securetoken@system.gserviceaccount.com", "audience": "' +
            state.applicationName +
            '", "issuer": "' +
            issuer +
            '"}';
          setState({
            ...state,
            output: finalOutput,
            btnText: "Generate Config",
          });
          break;
        default:
      }
    }
  };

  return (
    /* Use global styles normally */
    <Layout location={props.location}>
      <Seo
        title="Generate JWT Config | Hasura GraphQL Engine"
        description="Generate JWT Config for Auth0, Firebase to use with Hasura GraphQL Engine."
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <div className="commonSectionWrapper removePaddBottom">
        <div className="jWTConfigWrapper">
          <h1 className="articleSubTitle">JWT Config</h1>
          <form className="formWrapperjwt" onSubmit={generateOutput}>
            <div className="form-group formGroup">
              <label htmlFor="select">Select Provider</label>
              <select
                id="select"
                className={"form-control"}
                onBlur={providerChanged}
                onChange={providerChanged}
              >
                <option value="select">Select provider</option>
                <option value="auth0">Auth0</option>
                <option value="firebase">Firebase</option>
              </select>
            </div>
            {state.currentProvider === "auth0" ? (
              <div className="form-group formGroup">
                <label htmlFor="domainname">Enter Auth0 Domain Name</label>
                <input
                  id="domainname"
                  placeholder="myapp.auth0.com"
                  aria-label="domainname"
                  className={"form-control"}
                  onChange={applicationNameChanged}
                  type="text"
                />
              </div>
            ) : null}
            {state.currentProvider === "firebase" ? (
              <div className="form-group formGroup">
                <label htmlFor="projectid">Enter Firebase Project ID</label>
                <input
                  id="projectid"
                  placeholder=""
                  aria-label="projectid"
                  className={"form-control"}
                  onChange={applicationNameChanged}
                  type="text"
                />
              </div>
            ) : null}
            <div className="form-group commonBtnJwt">
              <button className={"btn btn-default"} type="submit">
                {state.btnText}
              </button>
            </div>
            <div className="form-group formGroup removeMarBottom outputWrapper">
              <label htmlFor="config">JWT Config </label>
              <span className="howToUse">
                <a
                  target="_blank"
                  href={`https://${baseDomain}/docs/latest/graphql/core/auth/authentication/jwt.html#running-with-jwt`}
                  rel="noopener noreferrer"
                >
                  (Where do i use this?)
                </a>
                <span className="overlayWrapper">
                  <Image
                    src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669274255/main-web/icons/circle-info-solid_mky8nv.svg"
                    alt="info-icon"
                    minWidth="16px"
                    width="16px"
                    ml="5px"
                  />
                  <div className="overlay">
                    <div className="downArrow"></div>
                    Use this generated value in HASURA_GRAPHQL_JWT_SECRET environment variable or
                    --jwt-secret server flag.
                  </div>
                </span>
              </span>
              <textarea
                rows="12"
                aria-label="output"
                className={"form-control"}
                defaultValue={state.output}
                type="text"
              />

              <CopyToClipboard text={state.output} onCopy={() => setState({ copied: true })}>
                <Image
                  src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1669274255/main-web/icons/copy-solid_dsb24r.svg"
                  alt="copy-icon"
                  minWidth="16px"
                  width="16px"
                />
              </CopyToClipboard>
              <div className="noteText">
                <b>Note</b>: If you are using docker-compose.yaml, please wrap the above in a single
                quote
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default JwtConfig;
